import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ANIMATION } from '../../styles/cssVariables'

import IconTwitterSVG from '../../images/icons/twitter.svg'
import IconFacebookSVG from '../../images/icons/facebook.svg'
import IconLinkedInSVG from '../../images/icons/linkedIn.svg'
import IconInstagramSVG from '../../images/icons/instagram.svg'

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: calc(28px - 10px);

  > * {
    margin: 0 5px;
    &:first-child {
      margin-left: 0;
    }
    &::last-child {
      margin-right: 0;
    }
  }
`

const IconLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > svg path:first-child:not(#Path_44168) {
    fill: var(--color-skobeloff);
  }
  > svg path {
    transition: fill ${ANIMATION.speedMedium} ease;
  }
  &:hover > svg path:first-child:not(#Path_44168) {
    fill: var(--color-skobeloff);
  }
`

/**
 * Renders clickable social media icon SVGs as per brand guidelines.
 * Accepts props from the Profile data type in the CMS to populate social media links.
 */
const SocialMediaIcons = (props) => {
  return (
    <IconsContainer className={props.className}>
      {props.twitterProfileLink && (
        <IconLink
          href={props.twitterProfileLink}
          target="_blank"
          rel="noreferrer"
        >
          <IconTwitterSVG alt={`${props.name} Twitter link`} />
        </IconLink>
      )}
      {props.facebookProfileLink && (
        <IconLink
          href={props.facebookProfileLink}
          target="_blank"
          rel="noreferrer"
        >
          <IconFacebookSVG alt={`${props.name} Facebook link`} />
        </IconLink>
      )}
      {props.linkedInProfileLink && (
        <IconLink
          href={props.linkedInProfileLink}
          target="_blank"
          rel="noreferrer"
        >
          <IconLinkedInSVG alt={`${props.name} LinkedIn link`} />
        </IconLink>
      )}
      {props.instagramProfileLink && (
        <IconLink
          href={props.instagramProfileLink}
          target="_blank"
          rel="noreferrer"
        >
          <IconInstagramSVG alt={`${props.name} Instagram link`} />
        </IconLink>
      )}
    </IconsContainer>
  )
}

SocialMediaIcons.propTypes = {
  twitterProfileLink: PropTypes.string,
  facebookProfileLink: PropTypes.string,
  linkedInProfileLink: PropTypes.string,
  instagramProfileLink: PropTypes.string,
}

export default SocialMediaIcons
